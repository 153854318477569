/* eslint-disable import/no-unresolved */
import { getUser } from '@/services/jwt.service';

const user = getUser();

const initialState = {
  isAuthenticated: !!user,
  user: user || {},
  errors: {},
};

export default {
  ...initialState,
};
