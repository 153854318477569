/* eslint-disable import/no-unresolved */
import UsersService from '@/services/users.service';
import ApiService from '@/services/api.service';
import { getToken } from '@/services/jwt.service';
// import WebSocketClient from "@/lib/websocket-client";
// import {API_URL} from '@/services/api.service';
// const webSocketClient = new WebSocketClient(API_URL + "/ws");

export default {
  // eslint-disable-next-line no-empty-pattern
  aSignUp({ }, payload) {
    // commit
    return new Promise((resolve, reject) => {
      UsersService.signUp(payload).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  },
  aSignIn({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.signIn(payload).then((response) => {
        // console.log('response', response);
        commit('mSetAuth', response.data);

        dispatch('aInit');
        resolve(response);
      }).catch((err) => {
        // alert(err);
        reject(err);
      });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  aChangePassword({}, payload) {
    // commit
    return new Promise((resolve, reject) => {
      UsersService.changePassword(payload).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  },
  async aLogout({ commit }) {
    commit('mPurgeAuth');
  },
  aSubscribe({ state }) {
    const user = state;
    // console.log("user", user);
    if (user) {
      // webSocketClient.connect();
      // webSocketClient.subscribe(payload.id, '/user/' + user.user.id + payload.endpoint, payload.onMessageReceived, typeof payload.renew != "undefined" ? payload.renew : false);
    }
    // (subscriberId, endpoint, onMessageReceived, renew = false)

    // const webSocketClient = state.webSocketClient;
    // commit('mPurgeAuth');
  },
  aCheckAuth({ commit }) {
    this._vm.$log.debug('aCheckAuth');
    if (getToken()) {
      ApiService.setHeader();
    } else {
      this._vm.$log.debug('aCheckAuth', 'mPurgeAuth');
      commit('mPurgeAuth');
    }
  },
  async aInit({ dispatch, commit }) {
    this._vm.$log.debug('aInit');
  },
};
