import dayjs from 'dayjs';

import { v4 as uuidv4 } from 'uuid';

export const createModel = () => {
  const model = {
    code: uuidv4(),
    uiKey: uuidv4(),
    name: null,
    model_code: `${new Date().getTime()}`,
    model_architecture: 'random forest',
    independent_variable_names_selected_by_user: ['day_of_year', 'Lat best', 'Long best'],
    model_name: `Model ${new Date().getTime()}`,
    description: null,
    datasets: [],
  };

  addDataset(model, 'y', {
    title: 'What do you want to predict ?',
    subtitle: 'Choose data ',
    label: 'Select Data To Predict',
  });

  addDataset(model, 'x', {
    title: 'What do you know?',
    subtitle: 'Choose dataset',
    label: 'Add Dataset',
  });

  addDataset(model, 'x', {
    title: 'What else?',
    subtitle: 'Choose dataset',
    label: 'Add Dataset',
  });

  addDataset(model, 'x', {
    title: 'Anything else?',
    subtitle: 'Choose another dataset',
    label: 'Add Dataset',
  });

  addDataset(model, 'build', {
    title: 'Build and predict',
    subtitle: 'Train a model',
    label: 'Add Dataset',
  });

  // addDataset(model, 'evaluate', {
  //   title: 'Evaluate model',
  //   subtitle: 'Finalise parameters',
  //   label: 'Add Dataset',
  // });

  return { ...model };
};

export const exportModel = (model) => {
  model.name = true;
  return model;
};

export const loadModel = (model) => {
  model.name = true;
  return model;
};

export const createSQLQuery = (model) => {
  model.name = true;
  return model;
};

export const createDataset = (axis, params, model) => {
  const dataset = {
    code: uuidv4(),
    uiKey: uuidv4(),
    name: null,
    table_name: null,
    variables: null,
    algorithm: 'linear regression',
    days_to_predict: 60,
    for_multiple_values: 'sum',
    for_missing_value: 'fill_with_zero',
    values: {},
    start_date: '2012-03-01',
    end_date: dayjs().format('YYYY-MM-DD'),
    axis,
    filters: {},
    remember_filters: ['location_type', 'natural_frequency'],
    lists: {},
  };
  if (axis === 'build') {
    dataset.name = model.model_name;
    dataset.start_date = '2022-01-01';
    dataset.start_test_date = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    dataset.end_date = '2022-12-31';
    dataset.independent_variable_names_selected_by_user = ['day_of_year', 'Lat best', 'Long best'];
  }
  return { ...params, ...dataset };
};

export const addDataset = (model, axis, params) => {
  const result = { ...model };
  const dataset = createDataset(axis, params, result);
  dataset.order = result.datasets.length + 1;
  result.datasets.push(dataset);
  return result;
};

export const removeDataset = (model, code) => {
  const result = { ...model };
  result.datasets = result.datasets.filter((item) => item.code !== code);
  return result;
};

export const getSelectedX = (model) => {
  if (!model) {
    return [];
  }
  const xDatasets = model.datasets.filter((item) => item.axis === 'x');
  let i = 0;
  const xs = [];
  xDatasets.forEach((dataset) => {
    i++;
    if (dataset.table_name) {
      const code = `x${i}`;
      const name = `${code}:${dataset.table_name.replace('orr_', '').replaceAll('_', ' ').toUpperCase()}`;
      xs.push({ code, name });
    }
  });
  return xs;
};
