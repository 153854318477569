export default () => new Promise((resolve) => {
  resolve({
    common: {
      title: 'Um exemplo simples',
      subtitle: 'É uma página em branco. Comece a construir sua página aqui.',
      error_with_status: 'Ocorreu um erro no servidor',
      404: 'Não foi possível encontrar esta página.',
      '404_subtitle': 'Você pode ficar e relaxar aqui, ou voltar ao começo.',
      back: 'voltar para casa',
      error_without_status: 'Ocorreu um erro no servidor',
      contact_title: 'Orçamento gratuito do projeto',
      contact_title2: 'Diga Olá para nós',
      contact_subtitle: 'Você tem alguma dúvida? Você está pronto para reduzir custos e criar novas receitas? Ou simplesmente quer dizer oi? Envie-nos uma mensagem.',
      form_name: 'Qual é o seu nome? *',
      form_email: 'Qual é o seu email? *',
      form_phone: 'Qual é o seu número de telefone?',
      form_company: 'Qual é a sua empresa?',
      form_message: 'Escreva sua mensagem aqui',
      form_terms: 'Li e aceito os Termos de',
      form_privacy: 'Serviço e política de privacidade *',
      form_send: 'Enviar mensagem',
      login: 'login',
      login_create: 'Crie uma nova conta',
      login_or: 'Ou entre com o e-mail',
      login_email: 'E-mail',
      login_password: 'Senha',
      login_remember: 'Lembre-se',
      login_forgot: 'Esqueceu a senha',
      continue: 'Continuar',
      login_title: 'Bem-vindo de volta',
      login_subtitle: 'Faça o login para continuar',
      register: 'Registo',
      register_already: 'Já possui uma conta?',
      register_or: 'Ou registre-se com o email',
      register_name: 'Nome? *',
      register_email: 'E-mail? *',
      register_password: 'Senha',
      register_confirm: 'Confirmar senha',
      register_title: 'Prazer em conhecê-lo :)',
      register_subtitle: 'Basta registrar-se para se juntar a nós',
      accept: 'aceitar',
      en: 'English',
      de: 'Deutsch',
      zh: '简体中文',
      pt: '󠁥󠁮󠁧󠁿Português',
      id: '󠁥󠁮󠁧󠁿Bahasa Indonesia',
      ar: '󠁥󠁮󠁧󠁿العربيّة',
      notif_msg: 'Este é um exemplo de mensagem comumente usada para informações importantes para visitantes ou usuários. Clique no botão ao lado para fechar esta mensagem.',
    },
    mobileLanding: {
      header_language: 'língua',
      header_theme: 'Modo de tema',
      header_dark: 'escuro',
      header_light: 'luz',
      header_feature: 'característica',
      header_showcase: 'mostruário',
      header_testimonials: 'depoimentos',
      header_faq: 'faq',
      header_contact: 'contato',
      banner_title: 'Bem-vindo ao seu celular',
      banner_titlestrong: 'centro de comando',
      banner_desc: 'É uma ferramenta completa para acompanhar o desempenho de suas campanhas.',
      counter_downloads: 'downloads',
      counter_transaction: 'transação',
      counter_ratting: 'Avaliação',
      feature_title: 'característica',
      feature_title1: 'Aumente as receitas de aplicativos para dispositivos móveis',
      feature_desc1: 'Aumente as vendas e as conversões para dispositivos móveis usando aplicativos móveis totalmente nativos. Obtenha até 5 vezes mais conversões do que a Web para dispositivos móveis.',
      feature_title2: 'Criar e gerenciar no celular',
      feature_desc2: 'Você pode selecionar parâmetros como seu objetivo e público. Você também pode gerenciar o trabalho criativo, escrever, copiar e revisar em diferentes dispositivos.',
      feature_title3: 'Veja como funciona',
      feature_desc3: 'Oferecemos soluções para você trabalhar com facilidade, independentemente de fazer negócios.',
      feature_watch: 'Assista ao vídeo',
      feature_video: 'Para aplicativos para celular - automatize anúncios para celular',
      showcase_title: 'São as ferramentas que você precisa',
      faq_subtitle: 'Tem uma pergunta? Consulte nossas perguntas frequentes para encontrar sua resposta.',
      testimonial_title: 'depoimentos',
      company_title: 'Confiável por organizações em todo o mundo',
      news_title: 'Últimas notícias',
      news_desc: 'Leia mais sobre as últimas notícias e nosso evento especial',
      footer_text: 'O que você está esperando? Baixe agora!',
    },
  });
});
