import Vue from 'vue';
import Router from 'vue-router';

import {
  store,
} from './store/store';

Vue.use(Router);

const publicRoutes = [
  {
    path: '',
    meta: {
      requiresAuth: false,
    },
    name: 'model-builder-home',
    component: () => import('./views/public/model-builder'),
  },
  {
    path: 'model-builder',
    meta: {
      requiresAuth: false,
    },
    name: 'model-builder',
    component: () => import('./views/public/model-builder'),
  },
  {
    path: 'playground',
    meta: {
      requiresAuth: false,
    },
    name: 'playground',
    component: () => import('./views/public/playground'),
  },
  {
    path: 'dashboard',
    meta: {
      requiresAuth: false,
    },
    name: 'dashboard',
    component: () => import('./views/public/dashboard'),
  },
  {
    path: 'spreadsheet',
    meta: {
      requiresAuth: false,
    },
    name: 'spreadsheet',
    component: () => import('./views/public/spreadsheet'),
  },
];

const mainRoutes = [
  {
    path: '/',
    meta: {
      requiresAuth: false,
    },
    component: () => import('./layouts/default'),
    children: [
      ...publicRoutes,
    ],
  },
  {
    name: 'HomeRedirect',
    path: '/',
    redirect: '/model-builder',
  },
  {
    path: '*',
    redirect: '/',
  },
];

const routes = [
  ...mainRoutes,
];

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior() {
    const timeout = setTimeout(() => {
      const preloader = document.getElementById('preloader');
      if (preloader !== null || undefined) {
        preloader.remove();
      }
      clearTimeout(timeout);
    }, 500);
    return {
      x: 0,
      y: 0,
    };
  },
});

const checkLogin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    // if (store.getters.can('read', to.name)) {
    next();
    return;
    // }
    // console.log('backToIndex');
    // router.push('/');
    // return;
  }

  const {
    path,
    query,
  } = to;
  // console.log('performLogin');
  store.dispatch('performLogin', {
    path,
    query,
  });
};

router.beforeEach(checkLogin);

export default router;
