/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VueI18n from 'vue-i18n';
import ApiService from '@/services/api.service';
import { getUser, getToken } from '@/services/jwt.service';
import VueMq from 'vue-mq';
import VueLogger from 'vuejs-logger';
import Vue2Filters from 'vue2-filters';
import 'vuetify/src/styles/styles.sass';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import '@/vuetify/styles/settings/_colors.scss';
// import '@/vuetify/src/styles/styles.sass'
import './assets/transition.scss';

import './assets/vuetify-overide.scss';

import './assets/vendors/animate.css';

import './assets/vendors/animate-extends.css';

import './assets/vendors/hamburger-menu.css';

import './assets/vendors/slick-carousel/slick.css';

import './assets/vendors/slick-carousel/slick-theme.css';

// import { VueMaskDirective } from 'v-mask';
// // import VueMask from 'v-mask';
// import '@mdi/font/css/materialdesignicons.css';
// import PerfectScrollbar from 'vue2-perfect-scrollbar';
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
// import ECharts from 'vue-echarts';
// import 'echarts';
// import CKEditor from 'ckeditor4-vue';
// import Fragment from 'vue-fragment';

// import 'vue2-datepicker/index.css';

// import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
// import 'vue-datetime/dist/vue-datetime.css';

// import EchartAll from 'echarts/core/a';

// import ECharts modules manually to reduce bundle size
// import {
//   CanvasRenderer
// } from 'echarts/renderers'
// import {
//   BarChart
// } from 'echarts/charts'
// import {
//   GridComponent,
//   TooltipComponent
// } from 'echarts/components'

// use([
//   CanvasRenderer,
//   BarChart,
//   GridComponent,
//   TooltipComponent
// ]);

// register globally (or you can do it locally)

// import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js';
// import { required, min, max, email } from 'vee-validate/dist/rules.esm.js';
// import veeEn from 'vee-validate/dist/locale/en';

// // Add the rules you need.
// Validator.extend('required', required);
// Validator.extend('min', min);
// Validator.extend('max', max);
// Validator.extend('email', email);
// // Merge the messages.
// Validator.localize('en', veeEn);

// Vue.use(VeeValidate);

import VeeValidate from 'vee-validate';

import { Plugin } from 'vue-fragment';
import VueScrollactive from 'vue-scrollactive';

// import options from 'vue-countup-directive/src';

import VueWow from 'vue-wow';

import VueProgressBar from 'vue-progressbar';
import { messages } from './static/lang';

import { store } from './store/store';
import router from './router';

import App from './App.vue';

Vue.use(Vue2Filters);

Vue.use(DatePicker);

// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === 'production';

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

// mount with global
Vue.use(VueWow);

// Vue.directive('countUp', options);

// use default options
Vue.use(VueScrollactive);

Vue.use(Plugin);

Vue.use(VeeValidate);
Vue.use(VueI18n);
Vue.use(VueMq, {
  // Default breakpoint for SSR
  defaultBreakpoint: 'default',
  breakpoints: {
    xsDown: 599,
    xsUp: 600,
    smDown: 959,
    smUp: 960,
    mdDown: 1279,
    mdUp: 1280,
    lgDown: 1919,
    lgUp: 1920,
    xl: Infinity,
  },
});

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});
// Vue.use(VueHtml2Canvas);

Vue.use(VueProgressBar, {
  color: 'blue',
  failedColor: 'red',
  height: '20px',
});

// Vue.use(Datetime);

// Vue.directive('mask', VueMaskDirective);
// // Vue.use(VueMask);
// Vue.use(Fragment.Plugin);
// Vue.use(CKEditor);
// Vue.component('VChart', ECharts);
// // Vue.component('Datetime', Datetime);
// Vue.use(PerfectScrollbar);

Vue.config.productionTip = false;

ApiService.init();

router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch('users/aCheckAuth')]).then(() => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const user = getUser();

      if (getToken() == null || user == null) {
        next({
          name: 'LogIn',
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });

  // Promise.all([store.dispatch('users/aCheckAuth')]).then(next)
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app_main');
