export default () => new Promise((resolve) => {
  resolve({
    common: {
      title: 'مثال بسيط',
      subtitle: 'إنها صفحة فارغة. ابدأ في إنشاء صفحتك من هنا.',
      error_with_status: 'حدث خطأ على الخادم',
      404: 'تعذر العثور على هذه الصفحة.',
      '404_subtitle': 'يمكنك إما البقاء والاسترخاء هنا ، أو العودة إلى البداية.',
      back: 'العودة إلى المنزل',
      error_without_status: 'حدث خطأ على الخادم',
      contact_title: 'مشروع مجاني اقتباس',
      contact_title2: 'قل مرحبا لنا',
      contact_subtitle: 'هل لديك أي أسئلة؟ هل أنت مستعد لخفض التكلفة وتحقيق إيرادات جديدة؟ أو هل تريد فقط أن تقول مرحبا؟ أرسل لنا رسالة.',
      form_name: 'ما اسمك؟ *',
      form_email: 'ما هو بريدك الإلكتروني؟ *',
      form_phone: 'ما هو رقم هاتفك؟',
      form_company: 'ما هي شركتك؟',
      form_message: 'اكتب رسالتك هنا',
      form_terms: 'لقد قرأت ووافقت على شروط',
      form_privacy: 'سياسة الخدمة والخصوصية *',
      form_send: 'إرسال رسالة',
      login: 'تسجيل الدخول',
      login_create: 'إنشاء حساب جديد',
      login_or: 'أو تسجيل الدخول عبر البريد الإلكتروني',
      login_email: 'البريد الإلكتروني',
      login_password: 'كلمه السر',
      login_remember: 'تذكر',
      login_forgot: 'نسيت كلمة المرور',
      continue: 'استمر',
      login_title: 'مرحبًا بك مرة أخرى',
      login_subtitle: 'الرجاء تسجيل الدخول للمتابعة',
      register: 'تسجيل',
      register_already: 'هل لديك حساب بالفعل؟',
      register_or: 'أو التسجيل باستخدام البريد الإلكتروني',
      register_name: 'الاسم؟ *',
      register_email: 'البريد الإلكتروني؟ *',
      register_password: 'كلمه السر',
      register_confirm: 'تأكيد كلمة المرور',
      register_title: 'تشرفت بمعرفتك :)',
      register_subtitle: 'فقط سجل للانضمام إلينا',
      accept: 'قبول',
      en: 'English',
      de: 'Deutsch',
      zh: '简体中文',
      pt: '󠁥󠁮󠁧󠁿Português',
      id: '󠁥󠁮󠁧󠁿Bahasa Indonesia',
      ar: '󠁥󠁮󠁧󠁿العربيّة',
      notif_msg: 'هذا مثال لرسالة شائعة الاستخدام للحصول على معلومات مهمة للزوار أو المستخدمين. الرجاء النقر فوق الزر بجانب إغلاق هذه الرسالة.',
    },
    mobileLanding: {
      header_language: 'لغة',
      header_theme: 'وضع السمة',
      header_dark: 'داكن',
      header_light: 'ضوء',
      header_feature: 'خاصية',
      header_showcase: 'عرض',
      header_testimonials: 'شهادات',
      header_faq: 'faq',
      header_contact: 'اتصل',
      banner_title: 'مرحبًا بك في هاتفك المحمول',
      banner_titlestrong: 'مركز القيادة',
      banner_desc: 'إنها أداة الكل في واحد لتتبع مدى أداء حملاتك.',
      counter_downloads: 'التحميلات',
      counter_transaction: 'المعاملة',
      counter_ratting: 'تقييم',
      feature_title: 'خاصية',
      feature_title1: 'زيادة إيرادات تطبيقات الجوال',
      feature_desc1: 'قم بزيادة مبيعات الهواتف المحمولة وتحويلاتها باستخدام تطبيقات Mobileify الأصلية تمامًا. احصل على تحويل يصل إلى 5 مرات أكثر من شبكة الجوال.',
      feature_title2: 'إنشاء وإدارة على الجوال',
      feature_desc2: 'يمكنك تحديد معلمات مثل الهدف والجمهور. يمكنك أيضًا إدارة العمل الإبداعي والكتابة والنسخ والمراجعة على أجهزة مختلفة.',
      feature_title3: 'انظر كيف يعمل',
      feature_desc3: 'نحن نقدم لك حلولاً للحصول على عمل بسهولة ، لكنك تمارس نشاطًا تجاريًا.',
      feature_watch: 'شاهد الفيديو',
      feature_video: 'لتطبيقات الجوال - أتمتة إعلانات الجوال',
      showcase_title: 'إنها الأدوات التي تحتاجها',
      faq_subtitle: 'هل لديك سؤال؟ راجع الأسئلة الشائعة للعثور على إجابتك.',
      testimonial_title: 'الشهادات - التوصيات',
      company_title: 'موثوق به من قبل المنظمات في جميع أنحاء العالم',
      news_title: 'آخر الأخبار',
      news_desc: 'اقرأ المزيد عن آخر الأخبار والحدث الخاص بنا',
      footer_text: 'ماذا تنتظر؟ تنزيل الآن!',
    },
  });
});
