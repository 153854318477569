/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';
import model from './modules/model';
import ApiService from '../services/api.service';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    users,
    model,
  },
  state: {
    layout: 'default',
    appConfig: null,
    showAlert: false,
    alertMessage: null,
    loading: false,
    subLoading: false,
  },
  mutations: {
    mSetLoading(state, status) {
      state.loading = status;
    },
    mSetSubLoading(state, status) {
      state.subLoading = status;
    },
    mSetAppConfig(state, config) {
      state.appConfig = config;
    },
    mShowAlert(state, alertMessage) {
      state.alertMessage = alertMessage;
      state.showAlert = true;
    },
  },
  getters: {
    isAuthenticated: () => true,
  },
  actions: {
    async aInit({ commit }) {
      try {
        const response = await ApiService.query('/config', {});
        commit('mSetAppConfig', response.data);
      } catch (error) {
        //
      }
    },
  },
});

export {
  store,
};
