// const loadBooleanEnv = (envName) => process.env[envName] === 'true' || process.env[envName] === true;
/* eslint-disable no-undef */
const { version: VERSION } = require('../package.json');

const {
  API_URL,
} = process.env;

const env = {
  VERSION,
  API_URL,
};

module.exports = env;
