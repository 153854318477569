/* eslint-disable import/no-unresolved */

export default {
  step: 1,
  dataset: null,
  model: null,
  loading: false,
  models: [],
  display: 'annotated',
  lastCode: null,
};
