export default () => new Promise((resolve) => {
  resolve({
    common: {
      title: 'Ein einfaches Beispiel',
      subtitle: 'Es ist eine leere Seite. Baue deine Seite mit here auf',
      error_with_status: 'Auf dem Server ist ein Fehler aufgetreten',
      404: 'Diese Seite konnte nicht gefunden werden.',
      '404_subtitle': 'Sie können entweder hier bleiben und entspannen oder zum Anfang zurückkehren.',
      zurück: 'zurück nach Hause',
      error_without_status: 'Auf dem Server ist ein Fehler aufgetreten',
      contact_title: 'Kostenloses Projektangebot',
      contact_title2: 'Sag Hallo zu uns',
      contact_subtitle: 'Haben Sie Fragen? Sind Sie bereit, Kosten zu senken und neue Einnahmen zu generieren? Oder möchten Sie einfach nur Hallo sagen? Schreiben Sie uns eine Nachricht.',
      form_name: 'Wie ist Ihr Name? *',
      form_email: 'Wie lautet Ihre E-Mail-Adresse? *',
      form_phone: 'Wie lautet Ihre Telefonnummer?',
      form_company: 'Was ist Ihre Firma?',
      form_message: 'Geben Sie hier Ihre Nachricht ein',
      form_terms: 'Ich habe die AGB von gelesen und akzeptiere sie.',
      form_privacy: 'Service & Datenschutz *',
      form_send: 'Nachricht senden',
      login: 'Einloggen',
      login_create: 'Neues Konto erstellen',
      login_or: 'Oder mit E-Mail einloggen',
      login_email: 'Email',
      login_password: 'Passwort',
      login_remember: 'Merken',
      login_forgot: 'Passwort vergessen',
      continue: 'Weiter',
      login_title: 'Willkommen zurück',
      login_subtitle: 'Bitte einloggen um fortzufahren',
      register: 'Register',
      register_already: 'Haben Sie schon ein Konto?',
      register_or: 'Oder registriere dich per E-Mail',
      register_name: 'Name? *',
      register_email: 'Email? *',
      register_password: 'Passwort',
      register_confirm: 'Passwort bestätigen',
      register_title: 'Schön dich zu treffen :)',
      register_subtitle: 'Einfach registrieren, um bei uns mitzumachen',
      accept: 'Accept',
      en: 'English',
      de: 'Deutsch',
      zh: '简体中文',
      pt: '󠁥󠁮󠁧󠁿Português',
      id: '󠁥󠁮󠁧󠁿Bahasa Indonesia',
      ar: '󠁥󠁮󠁧󠁿العربيّة',
      notif_msg: 'Dies ist ein Beispiel für eine Nachricht, die häufig für wichtige Informationen für Besucher oder Benutzer verwendet wird. Bitte klicken Sie auf die Schaltfläche neben, um diese Nachricht zu schließen.',
    },
    mobileLanding: {
      header_language: 'Sprache',
      header_theme: 'Themenmodus',
      header_dark: 'dunkel',
      header_light: 'Licht',
      header_feature: 'feature',
      header_showcase: 'Vitrine',
      header_testimonials: 'Referenzen',
      header_faq: 'faq',
      header_contact: 'Kontakt',
      banner_title: 'Willkommen auf deinem Handy',
      banner_titlestrong: 'Kommandozentrale',
      banner_desc: 'Dies ist ein All-in-One-Tool zum Verfolgen der Leistung Ihrer Kampagnen.',
      counter_downloads: 'downloads',
      counter_transaction: 'Transaktion',
      counter_ratting: 'Bewertung',
      feature_title: 'feature',
      feature_title1: 'Mobile App-Einnahmen steigern',
      feature_desc1: 'Steigern Sie Verkäufe und Conversions von Mobilgeräten mit nativen-Mobilanwendungen. Erzielen Sie bis zu 5-mal mehr Conversions als im mobilen Web.',
      feature_title2: 'Auf dem Handy erstellen und verwalten',
      feature_desc2: 'Sie können Parameter wie Ihr Ziel und Ihre Zielgruppe auswählen. Sie können auch kreative Arbeit verwalten, auf verschiedenen Geräten schreiben, kopieren und überprüfen.',
      feature_title3: 'Sehen Sie, wie es funktioniert',
      feature_desc3: 'Wir bieten Lösungen, mit denen Sie problemlos arbeiten können, ganz gleich, wie Sie Geschäfte machen.',
      feature_watch: 'Video ansehen',
      feature_video: 'Für mobile Apps - mobile Anzeigen automatisieren',
      showcase_title: 'Es sind die Werkzeuge, die Sie brauchen',
      faq_subtitle: 'Haben Sie eine Frage? In unseren häufig gestellten Fragen finden Sie Ihre Antwort.',
      testimonial_title: 'Referenzen',
      company_title: 'Von Organisationen weltweit als vertrauenswürdig eingestuft',
      news_title: 'Neueste Nachrichten',
      news_desc: 'Lesen Sie mehr über die neuesten Nachrichten und unser besonderes Ereignis',
      footer_text: 'Worauf warten Sie noch? Jetzt herunterladen!',
    },
  });
});
