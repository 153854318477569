export const getFromStorage = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
    return null;
  }
};

export const removeFromStorage = (key) => {
  try {
    return window.localStorage.removeItem(key);
  } catch (err) {
    //
  }
};

export const saveInStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    //
  }
};

export const clearStorage = () => {
  try {
    window.localStorage.clear();
  } catch (err) {
    //
  }
};
